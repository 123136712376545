
*{
    margin: 0;
    padding: 0;
    scrollbar-color: rgba(192, 192, 219, 0.5) #F4F4FD;   /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width: thin;
}
    ::-webkit-scrollbar {
           width: 5px;
           height: 8px;
         }

    ::-webkit-scrollbar-thumb {
           background-color: #C1CBE2;
           border-radius: 10px;
    }

html,
body {
  background: #efefef;
  font-family: 'Gilroy', sans-serif;
  box-sizing: border-box;
}

 * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h3,p{
    margin: 0;
}

.divider {
    background-color: #D8D8D8;
    height: 0.5px;
}

.backgroud-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.6;
    overflow: hidden;
}
.App{
    max-width: 1920px;
    margin: 0 auto;
}

button {
    cursor: pointer;
    border: none;
  }
